export const defaultUrl = "";
export const appUrl = "https://link-ai.tech";
export const websocketUrl = 'link-ai.tech';

// /api/chat/wx/message/
// export const appUrl = "http://localhost:3000";
// export const websocketUrl = '193.112.62.183:7072'

// 测试环境
// export const appId = "wx082af5a4c3d4a43c" // appId
// export const versionNum = "0.3.0"; // 版本号
// export const appUrl = "http://43.159.43.58";
// export const websocketUrl = '193.112.62.183:7072'

// 生产环境
export const appId = "wx9bdd8623a1d433c1";
export const versionNum = "3.4.5"; // 版本号
  
    